export const LOCALES = {
  'de-DE': 'German (Germany)',
  'en-US': 'English (United States)',
  'en-GB': 'English (United Kingdom)',
  'en-PL': 'English (Pseudolocalized/Debug)',
  'fr-FR': 'French (France)',
  'ja-JP': 'Japanese (Japan)',
  'pt-BR': 'Portuguese (Brazil)',
  'es-ES': 'Spanish (Spain)',
  'pl-PL': 'Polish (Poland)',
  'tr-TR': 'Turkish (Turkey)',
  'zh-CN': 'Chinese (Simplified)',
  'gz-GZ': 'GenZ (Gen Z)',
} as const;

export const SUPPORTED_LOCALES = Object.keys(LOCALES) as Array<
  keyof typeof LOCALES
>;

export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];

export const DEFAULT_LOCALE = 'en-US';
export const PSEUDO_LOCALE = 'en-PL';

export const LOCALIZATION_ENVS = {
  test: 'Preview',
  production: 'Live',
} as const;

export const LocalizationEnvs = Object.keys(LOCALIZATION_ENVS) as Array<
  keyof typeof LOCALIZATION_ENVS
>;

export type LocalizationEnv = (typeof LocalizationEnvs)[number];
export const DEFAULT_LOC_ENV = 'production';
